import React, { Component, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import moment from "moment";
import { Row, Col, Card, Table as Tbl } from "react-bootstrap";
import { TextField, Button, FormControl } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import "./style.css";

import { API_URL } from "./../../global";

const axios = require("axios");

export default class BillManager extends Component {
    constructor(props) {
        super();

        this.state = {
            billId: null,
            partyId: null,
            partyName: null,
            newPartyName: null,
            address: null,
            partyMobile: null,
            gstin: null,
            billType: 1,

            routeName: "",

            date: moment(new Date()).format("YYYY-MM-DD"),

            particularValue: null,
            particular: null,
            quantity: 1,
            rate: 0,
            amount: 0,

            itemList: [],
            addedItems: [],

            advance: 0,
            total: 0,
            balance: 0,
            sgst: 0,
            cgst: 0,
            igst: 0,
            adjustment: 0,
            grandTotal: 0,

            printComponentRef: null,

            partyList: null,

            latestInsertId: 0,

            productList: [],

            routeOptions: [],

            currentProductCode: "",
        };
    }

    fetchRouteOptions() {
        let url = API_URL;

        let query = "SELECT name from routes;";
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                console.log("route options data : ", res.data);
                const opts = res.data.map((record) => record.name);
                this.setState({ routeOptions: opts });
            })
            .catch((err) => {
                console.log("route options error : ", err);
            });
    }

    getIdPartyList() {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `SELECT id, name, mobile, address FROM party;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                this.setState({ partyList: res.data });
            })
            .catch((err) => {
                console.log("id + name fetch error: ", err);
            });
    }

    getLatestId = () => {
        let url = API_URL;
        const query = `SELECT id FROM gstBill ORDER BY id DESC LIMIT 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("latest id data: ", res.data);
                this.setState({
                    billId: (res.data[0] != null ? res.data[0]["id"] : 0) + 1,
                });
            })
            .catch((err) => {
                console.log("latest id data fetch error: ", err);
            });
    };

    fetchProducts = () => {
        const query = `SELECT * FROM products where status=1`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                this.setState({ productList: res.data });
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("productlist data fetch error: ", err);
            });
    };

    setRate = () => {
        let res = this.state.productList.find(
            (record) => record.name === this.state.particular
        );
        res
            ? this.setState({ rate: res.unitPrice })
            : this.setState({ rate: 0 });
    };
    caluclateWeight = (field, value) => {
        if (field === "totalBoxes") {
            let weight = value * this.state.weightPerBox;
            this.setState({ totalBoxes: value });
            this.setState({ weight: weight });
        } else if (field === "weightPerBox") {
            let weight = this.state.totalBoxes * value;
            this.setState({ weightPerBox: value });
            this.setState({ weight: weight });
        }
    };

    calculateAmount = (field, value) => {
        if (field === "weight") {
            let amount = value * this.state.rate;
            this.setState({ weight: value });
            this.setState({ amount: amount });
        } else if (field === "rate") {
            let amount = this.state.weight * value;
            this.setState({ rate: value });
            this.setState({ amount: amount });
        }
    };

    // calculateTaxes = () => {
    //     const total = this.state.total;
    //     this.setState(
    //         {
    //             sgst: Number((total / 100) * 9).toFixed(2),
    //             cgst: Number((total / 100) * 9).toFixed(2),
    //             igst: Number((total / 100) * 18).toFixed(2),
    //         },
    //         this.calculateGrandTotal
    //     );
    // };

    // calculateGrandTotal = () => {
    //     let grandTotal;
    //     if (this.state.billType === 1) {
    //         grandTotal =
    //             Number(this.state.total) +
    //             Number(this.state.igst) +
    //             Number(this.state.adjustment);
    //     } else {
    //         grandTotal =
    //             Number(this.state.total) + Number(this.state.adjustment);
    //     }
    //     this.setState({ grandTotal: grandTotal.toFixed(2) });
    // };

    addItem = (productCode) => {
        // add product to list
        this.state.productList.find((product) => {
            if (product.productCode == productCode) {
                this.setState({
                    productId: product.id,
                    particular: product.name,
                    rate: product.unitPrice,
                });
            }
        });
        // clear input field
        this.setState({ currentProductCode: "" });
        console.log("in add items");
    };

    addItems = () => {
        if (!this.state.particular || !this.state.rate) return;
        // let items = this.state.itemList;
        let items = this.state.addedItems;
        const ifExists = items.find(
            (item) => item.particular === this.state.particular
        );
        if (ifExists) {
            items = items.map((item) => {
                if (item.particular === this.state.particular) {
                    return {
                        productId: this.state.productId,
                        particular: this.state.particular,
                        quantity: +item.quantity + +this.state.quantity,
                        rate: +item.rate + +this.state.rate,
                        amount:
                            +item.amount +
                            +this.state.rate * +this.state.quantity,
                    };
                } else {
                    return item;
                }
            });
        } else {
            items.push({
                productId: this.state.productId,
                particular: this.state.particular,
                quantity: this.state.quantity,
                rate: this.state.rate,
                amount: this.state.rate * this.state.quantity,
            });
        }

        this.setState({ addedItems: items });

        // update total & balance
        // let total = Number(this.state.total) + Number(this.state.amount);
        let total =
            Number(this.state.total) +
            Number(this.state.rate * this.state.quantity);
        this.setState({ total: total });
        let balance = total + Number(this.state.advance);
        this.setState({ balance: balance });
        // this.calculateTaxes();

        // set focus to product code input
        this.productCodeInput.focus();
    };

    deleteItem = (index) => {
        // let itemList = this.state.itemList;
        let itemList = this.state.addedItems;

        // update total & balance
        let total = this.state.total - itemList[index]["amount"];
        let balance = total + Number(this.state.advance);
        this.setState({ total: total }, this.calculateTaxes);
        this.setState({ balance: balance });

        // remove element
        // let updatedList = itemList.filter((item, _index) => {
        //     if (index !== _index) return item;
        // });
        // this.setState({ itemList: updatedList });
        let updatedList = itemList.filter((item, _index) => {
            if (index !== _index) return item;
        });
        this.setState({ addedItems: updatedList });
    };

    handleClear = () => {
        window.location.reload(false);
    };

    updateCount(productId, quantity) {
        const query = `UPDATE products set quantity = quantity - ${quantity} WHERE id = ${productId}`;
        const data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("product count updated successful");
            })
            .catch((err) => {
                console.log("failed to update product count, error: ", err);
            });
    }

    insertBillList = () => {
        let url = API_URL;

        // 1.  insert into deliveryMemoList
        this.state.addedItems.map((item, index) => {
            const query = `INSERT INTO billList(billType, billId, partyId, particular, quantity, rate, amount, status) VALUES(
          ${this.state.billType},
          ${this.state.billId},
          ${this.state.partyId},
          '${item.particular}', 
          ${item.quantity}, 
          ${item.rate}, 
          ${item.amount},
          1
        )`;
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    console.log("insert billList successful, index: ", index);
                    this.updateCount(item.productId, item.quantity);
                })
                .catch((err) => {
                    console.log("failed to insert billList, error: ", err);
                });
        });
    };

    insertLedgerRecord = () => {
        // 1.  insert into ledger
        const query = `INSERT INTO ledger(party_id, particular, total, memo_id) VALUES(${this.state.partyId}, 'reference bill id: ${this.state.latestInsertId}', ${this.state.total}, ${this.state.latestInsertId})`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("insert ledger successful");
                console.log("insert response: ", res.data.insertId);
            })
            .catch((err) => {
                console.log("failed to insert ledger, error: ", err);
            });
    };

    insertNewPartyAndSave = () => {
        const query = `INSERT INTO party (name, mobile) values("${this.state.newPartyName}", "${this.state.partyMobile}")`;
        const data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("insert party successful");
                console.log("insert response: ", res.data.insertId);
                this.setState({ partyId: res.data.insertId }, this.saveBill);
            })
            .catch((err) => {
                console.log("failed to insert party, error: ", err);
            });
    };

    saveBill = () => {
        const newDate = moment(new Date()).format("YYYY-MM-DD");
        let query;

        query = `INSERT INTO nonGstBill (partyId, total, adjustment, paid, balance, date) values("
        ${this.state.partyId}", 
        ${this.state.total - this.state.adjustment}, 
        ${this.state.adjustment}, 
        ${this.state.advance}, 
        ${this.state.total - this.state.adjustment - this.state.advance},
        "${newDate}")`;

        console.log(query);

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                toast.success("Bill Generated successfully");
                this.setState(
                    { billId: res.data.insertId },
                    this.insertBillList
                );
            })
            .catch((err) => {
                toast.error("Failed to Generate Bill ");
            });
    };

    handleSave = async (e) => {
        e.preventDefault();

        // check party already exists
        let partyId = this.state.partyId;
        if (partyId === null) {
            this.insertNewPartyAndSave();
        } else {
            this.saveBill();
        }
    };

    handleSavePrint = (e) => {
        console.log("in handle save print");
        // 1. handle save
        this.handleSave();
    };

    componentDidMount() {
        this.getLatestId();
        this.getIdPartyList();
        this.fetchRouteOptions();
        this.fetchProducts();
    }

    render() {
        return (
            <form className="mb-5" onSubmit={(e) => e.preventDefault()}>
                {/* Input Party Details */}

                <FormControl
                    style={{ minWidth: "250px" }}
                    className="mr-2 mb-2 smt-0"
                >
                    <Autocomplete
                        id="partyName"
                        freeSolo
                        options={
                            this.state.partyList != null
                                ? this.state.partyList.map(
                                      (item) => item.id + ", " + item.name
                                  )
                                : []
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                // label="party name"
                                label="Party Name"
                                variant="outlined"
                                size="small"
                                value={this.state.newPartyName}
                                onChange={(event) =>
                                    this.setState({
                                        newPartyName: event.target.value,
                                    })
                                }
                            />
                        )}
                        onChange={(event, value) => {
                            console.log(value);
                            if (value != null && value.length > 2) {
                                this.setState({
                                    partyId: value.split(", ")[0],
                                    partyName: value.split(", ")[1],

                                    partyMobile: this.state.partyList.find(
                                        (party) =>
                                            party.id == value.split(", ")[0]
                                    )?.mobile,
                                });
                            } else {
                                this.setState({
                                    partyId: null,
                                    partyName: "",
                                });
                            }
                        }}
                    />
                </FormControl>

                <TextField
                    id="partyMobile"
                    label="Mobile"
                    variant="outlined"
                    className="mr-2"
                    value={this.state.partyMobile || ""}
                    onChange={(e) =>
                        this.setState({ partyMobile: e.target.value })
                    }
                    // required="true"

                    size="small"
                />

                <TextField
                    id="adjustment"
                    label="Adjustment"
                    variant="outlined"
                    className="mr-2"
                    value={this.state.adjustment}
                    onChange={(e) =>
                        this.setState({ adjustment: e.target.value })
                    }
                    // required="true"
                    size="small"
                    type="number"
                    style={{ width: "150px" }}
                />
                <TextField
                    id="advance"
                    label="Advance"
                    variant="outlined"
                    className="mr-2"
                    value={this.state.advance}
                    onChange={(e) => this.setState({ advance: e.target.value })}
                    // required="true"
                    size="small"
                    type="number"
                    style={{ width: "150px" }}
                />

                {/* End of Input Party Details */}

                <hr />

                <Row>
                    <Col>
                        <TextField
                            id="productCode"
                            label="Product Code"
                            variant="outlined"
                            required="true"
                            size="small"
                            type="text"
                            className="mr-2 mt-1"
                            value={this.state.currentProductCode}
                            onInput={(e) => {
                                this.setState({
                                    currentProductCode: e.target.value,
                                });
                                if (e.target.value.length == 10) {
                                    this.addItem(e.target.value);
                                }
                            }}
                            ref={(input) => {
                                this.productCodeInput = input;
                            }}
                        />
                    </Col>
                    <Col>
                        <FormControl
                            style={{ minWidth: "250px" }}
                            className="mr-2 mb-2 smt-0"
                        >
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                options={
                                    this.state.productList != null
                                        ? this.state.productList.map(
                                              (item) => item.name
                                          )
                                        : []
                                }
                                value={this.state.particular}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // label="party name"
                                        label="product"
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                                onChange={(event, value) => {
                                    this.setState(
                                        { particular: value },
                                        this.setRate
                                    );
                                }}
                            />
                        </FormControl>
                    </Col>
                    <Col>
                        <TextField
                            id="rate"
                            label="Rate"
                            variant="outlined"
                            className="mr-2 mt-1"
                            value={this.state.rate}
                            onChange={(e) =>
                                this.setState({ rate: e.target.value })
                            }
                            required="true"
                            size="small"
                            type="number"
                        />
                    </Col>
                    <Col>
                        <TextField
                            id="quantity"
                            label="Quantity"
                            variant="outlined"
                            className="mr-2 mt-1"
                            value={this.state.quantity}
                            onChange={(e) =>
                                this.setState({ quantity: e.target.value })
                            }
                            required="true"
                            size="small"
                            type="number"
                        />
                    </Col>

                    <Col>
                        <Button
                            color="primary"
                            variant="contained"
                            className="mt-2"
                            onClick={this.addItems}
                            disabled={
                                !this.state.particular ||
                                !this.state.rate ||
                                !this.state.quantity
                            }
                        >
                            Add
                        </Button>
                    </Col>
                </Row>

                <div
                    className="mt-1 p-2 measure"
                    ref={(el) => (this.printComponentRef = el)}
                >
                    <Row>
                        <Col md={4} className="mx-auto">
                            <Card className="mt-2 p-0">
                                <Card.Header>
                                    <Row>
                                        <Col>
                                            <Card.Title className="text-center pb-0 mb-0">
                                                <b class="h5">
                                                    Siddhivinayak Enterprises
                                                </b>
                                                <hr />
                                            </Card.Title>
                                            <p className="text-center pb-0 mb-0">
                                                Shivdarshan Complex, Khanapur
                                                Road, Vita <br />
                                                Ta: Khanapur, Dist: Sangli, PIN:
                                                415311
                                            </p>
                                        </Col>
                                    </Row>
                                    <hr />

                                    <p className="text-center">
                                        Customer Care No. 9960565357 /
                                        9611227577
                                    </p>

                                    <p className="text-center">
                                        Email:
                                        siddhivinayakenterprisesvita@gmail.com
                                    </p>

                                    <hr />
                                    <p class="text-center my-0 py-0">INVOICE</p>
                                    <hr />

                                    <span>
                                        <Row>
                                            <div className="col-6 col-md-6">
                                                <p>
                                                    Bill No.{" "}
                                                    <b>{this.state.billId}</b>
                                                </p>
                                            </div>

                                            <div className="col-6 col-md-6">
                                                <p>
                                                    Date{" "}
                                                    <b>
                                                        {moment(
                                                            new Date()
                                                        ).format("D/M/YYYY")}
                                                    </b>
                                                </p>
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="col-6 col-md-6">
                                                <p
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Party:{" "}
                                                    <b>
                                                        {this.state.partyName ||
                                                            this.state
                                                                .newPartyName}
                                                    </b>
                                                </p>
                                            </div>

                                            <div className="col-6 col-md-6">
                                                <p
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Mob.:{" "}
                                                    <b>
                                                        {this.state.partyMobile}
                                                    </b>
                                                </p>
                                            </div>
                                        </Row>
                                    </span>
                                </Card.Header>

                                <Card.Body className="m-0 pt-0">
                                    {/* Order overview */}
                                    <Tbl striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>SI</th>
                                                <th>Description</th>
                                                <th>Qty</th>
                                                <th>Rate</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        {this.state.addedItems.length > 0 ? (
                                            <tbody>
                                                {this.state.addedItems.map(
                                                    (item, index) => {
                                                        return (
                                                            // <tr key={"" + item.particularValue.title}>
                                                            //   <td>{item.particularValue.title} </td>
                                                            <tr
                                                                key={
                                                                    "" +
                                                                    item.particular
                                                                }
                                                            >
                                                                <td>
                                                                    {index + 1}{" "}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.particular
                                                                    }{" "}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.quantity
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {Number(
                                                                        item.rate
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {Number(
                                                                        item.amount
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td
                                                                    className="d-print-none"
                                                                    align="center"
                                                                >
                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        className="mx-0 px-0"
                                                                        onClick={() =>
                                                                            this.deleteItem(
                                                                                index
                                                                            )
                                                                        }
                                                                        size="smallx`"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faTrash
                                                                            }
                                                                        />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                                <br />

                                                <tr>
                                                    <td colSpan="4">
                                                        Adjustment
                                                    </td>
                                                    <td colSpan="1">
                                                        {this.state.adjustment}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4">
                                                        <b>Grand Total</b>
                                                    </td>
                                                    <td colSpan="1">
                                                        <b>
                                                            {this.state.total -
                                                                this.state
                                                                    .adjustment}
                                                        </b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                <tr>
                                                    <td colSpan="6">
                                                        No items added
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </Tbl>
                                </Card.Body>
                                <Card.Footer>
                                    <p className="text-center">
                                        Goods once sold will not be taken back
                                        or exchanged
                                    </p>
                                    <p className="text-center">
                                        Keep Environment Clean
                                    </p>
                                    <p className="text-center">
                                        Thank You . Visit Again!
                                    </p>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <ReactToPrint content={() => this.printComponentRef}>
                    <PrintContextConsumer>
                        {({ handlePrint }) => (
                            <Button
                                onClick={handlePrint}
                                className="mt-2 mr-1"
                                color="primary"
                                variant="contained"
                                style={{ float: "right" }}
                            >
                                Print
                            </Button>
                        )}
                    </PrintContextConsumer>
                </ReactToPrint>

                <Button
                    className="mt-2 mr-1"
                    color="secondary"
                    variant="contained"
                    style={{ float: "right" }}
                    // type="submit"
                    onClick={this.handleSave}
                >
                    Save bill
                </Button>
                <Button
                    className="mt-2 mr-1"
                    color="light    "
                    variant="contained"
                    style={{ float: "right" }}
                    onClick={this.handleClear}
                >
                    clear
                </Button>
            </form>
        );
    }
}
