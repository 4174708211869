import React, { Component } from "react";

import {
    TableBody,
    TableContainer,
    Button,
    Paper,
    TextField,
    FormControl,
} from "@material-ui/core";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
// import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Col } from "react-bootstrap";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";

//API handling components
import { API_URL } from "./../../global";
const axios = require("axios");

export class AddNewEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            productCode: null,
            category: null,
            unitPrice: 0,
            quntity: 0,
            amount: 0,
            categoryList: [
                "Agarbatti Products",
                "Ayurvedic (Harbal)",
                "Dry Fruit",
                "Food Product",
                "General",
                "Oil Product",
                "Services",
                "Siddhagiri Kanerimath",
                "Tools & equipments",
            ],
        };
    }

    handleAddSubmit(e) {
        e.preventDefault();

        const query = `INSERT INTO products(productCode, name, category, unitPrice, quantity) VALUES('${this.state.productCode}', '${this.state.name}', '${this.state.category}', ${this.state.unitPrice}, ${this.state.quantity});`;

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                toast.success("Product record added successfully");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <div className="row">
                <form autoComplete="off">
                    <div className="row ml-4 mt-4">
                        <TextField
                            id="name"
                            label="name"
                            variant="outlined"
                            type="text"
                            className="mr-2"
                            required={true}
                            size="small"
                            onChange={(e) =>
                                this.setState({ name: e.target.value })
                            }
                        />

                        <TextField
                            id="productCode"
                            label="Product Code"
                            variant="outlined"
                            type="text"
                            className="mr-2"
                            size="small"
                            onChange={(e) =>
                                this.setState({ productCode: e.target.value })
                            }
                        />

                        <FormControl
                            style={{ minWidth: "230px" }}
                            className="mr-2 mb-2 smt-0"
                        >
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                options={
                                    this.state.categoryList != null
                                        ? this.state.categoryList.map(
                                              (item) => item
                                          )
                                        : []
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // label="party name"
                                        label="Category"
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                                onChange={(event, value) => {
                                    this.setState({
                                        category: value,
                                    });
                                }}
                            />
                        </FormControl>

                        <TextField
                            id="unitPrice"
                            label="Unit Price"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            size="small"
                            onChange={(e) =>
                                this.setState({ unitPrice: e.target.value })
                            }
                        />

                        <TextField
                            id="quantity"
                            label="Quantity"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            size="small"
                            onChange={(e) =>
                                this.setState({ quantity: e.target.value })
                            }
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            className="mb-3"
                            onClick={(e) => this.handleAddSubmit(e)}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            className="mb-3 ml-2"
                            onClick={this.props.refreshLedger}
                        >
                            <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                        </Button>
                    </div>
                </form>
                <ToastContainer />
            </div>
        );
    }
}
